import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-warehouseconfig',
  templateUrl: './warehouseconfig.component.html',
  styleUrls: ['./warehouseconfig.component.scss'],
})
export class WarehouseconfigComponent implements OnInit {

  page=3
  constructor(public service:WebapiService,public router:Router) { 

    this.getallratebook();
  }

  ngOnInit() {}

  id=-1;

  month=''
  year=''
  
  uploadstatus=false;
  uploadstatus_message=""
  resultr:any;
  filetype="Warehouse"

  upload_ratebook(e)
  {
    
      this.uploadstatus=true;
      this.uploadstatus_message="Please wait.."

      var form_data = new FormData();  
            //console.log(model);
        //     startdate= request.form.get('startdate')
        // expirydate= request.form.get('expirydate')
        // filetype= request.form.get('filetype')
        // _by= request.form.get('uby')
        // bisactive= request.form.get('bisactive')

            console.log(e.target.files)
            for(var i=0;i<e.target.files.length;i++)
                form_data.append('file', e.target.files.item(i)); 
            
                form_data.append('filetype', this.filetype); 
              
                form_data.append('smonth', this.month); 
                form_data.append('syear', this.year); 

                

                form_data.append('id', this.id+""); 


                form_data.append('uby', localStorage.getItem("email")); 

                this.service.postData1(form_data, 'upload_warehouse_monthly').then((result) => {
                  var temp: any = result;
                  this.resultr = temp
                  console.log("2");
                  this.uploadstatus=false;
                  this.uploadstatus_message="File uploaded successfully"

                  this.getallratebook();
                  
            
            }).catch(e => {
              this.uploadstatus=true;
              this.uploadstatus_message="Somthing goes wrong!";
                 
            });
    
           
  }

  Dl_flag=false;
  dl_i=-1
  delete(f,i){
    this.Dl_flag=true;

    this.dl_i=i;
    this.service.postData({id:f.iId}, 'delete_warehouse_monthly').then((result) => {
          
      var temp: any = result;
      this.Dl_flag=false;
      this.dl_i=-1
      console.log(temp)
      this.getallratebook()

    });
  }
  resultlist:any=[]
  loading=false;
  getallratebook()
  {
    this.loading=true;
    this.resultlist=[]
    this.service.postData({type:''}, 'getall_warehouse_monthly').then((result) => {
          
      var temp: any = result;
               
      this.loading=false;


              this.resultr = temp
              this.resultlist =this.resultr.data;
              var list = this.resultlist.filter(function(item:any){return item.sfiletype==1} ).reverse() 

              // this.startdate=list[0];
              this.id=list[0].iId;

    });
  }

  D_flag=false;
  D_i=-1;
  download_file(f,i) {

    this.D_i=i
  //   this.service.postData({}, 'download/'+type).then((result) => {
  //     var temp: any = result;
  //     this.resultr = temp
  //     console.log(this.resultr);
  
  // })

  //  filename=filename+'_to_'+ new Date(this.resultlist[i].dtexpirydate).getFullYear()+"_"+(new Date(this.resultlist[i].dtexpirydate).getMonth()+1)+"_"+new Date(this.resultlist[i].dtexpirydate).getDate();
  //  filename=filename+'_'+'partner_config_file.csv'
  var filename=f.syear+"_warehousedata.csv"
  this.D_flag=true;
  this.service.download_warehouse(f.iId,filename).then((result) => {
    this.D_flag=false;
    this.D_i=-1
  
  });
  }

  gotwconfig(){
    this.router.navigateByUrl('/warehouse');

  }


  
}

