import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-demandsensingconfig',
  templateUrl: './demandsensingconfig.component.html',
  styleUrls: ['./demandsensingconfig.component.scss'],
})
export class DemandsensingconfigComponent implements OnInit {
  threshold_config={
    warning_threshold_perc:70,
    overallocation_threshold_perc:90,
    volume:1000,
  }
  emailpref;
  lane_region;
  lane;
  filers;
  lanelist;
  filterselection ={
    fiscal_year:"",
    fiscal_quarter:"",
    fiscal_month:"",
    fiscal_week:"",
    theater_origin:"",
    theater_destination:"",
    region_origin:"",
    region_destination:"",
    airport_origin:"",
    airport_destination:"",
    monthweeklist:[],
selectedlane:"",
    date:[]


  }
  constructor(public modalctrl : ModalController,public service:WebapiService) {

    var threshold_config = localStorage.getItem("thresholdconfig");
    if(threshold_config)
      this.threshold_config=JSON.parse(threshold_config);

   }
  resetwarning(){

    this.threshold_config.warning_threshold_perc=70
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));

   }
   getrigionbhytheater(theater,theaterd)
  {
    console.log(theater,theaterd)
    if(theater!='')
    {
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theater } ) 
   
      this.filers.fiscal_region_origin = list
          .map((item) => item.REGION_NAME)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
  
    }
    else{
      this.filers.fiscal_region_origin = this.lane_region
          .map((item) => item.REGION_NAME)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    if(theaterd!='')
    {

        var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd } ) 
   
        this.filers.fiscal_region_destination= list
            .map((item) => item.REGION_NAME)
            .filter(
                (value, index, current_value) => current_value.indexOf(value) === index
            );

          }
          else{

            var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd } ) 
   
            this.filers.fiscal_region_destination= this.lane_region
                .map((item) => item.REGION_NAME)
                .filter(
                    (value, index, current_value) => current_value.indexOf(value) === index
                );
          }
         
        // this.getdistinctweek(fiscal_year,fiscal_quarter,this.filers.fiscalmonth[0]);
        this.getairpotbhytheater(this.filterselection.theater_origin,this.filterselection.theater_destination,this.filterselection.region_origin,this.filterselection.region_destination)
  }
  getairpotbhytheater(theater,theaterd,region,regiond){
    // console.log(theater,theaterd,region,regiond)
  
    

    if(theater!=''&&region!='')
    {
    //  console.log("1")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.THEATER_NAME===theater && item.REGION_NAME===region } ) 
        // console.log(list)
      
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );      
    }
    else if(theater!=''&&region==''){
      // console.log("2")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.THEATER_NAME===theater } ) 
      //////console.(list)
      // console.log(list)

  
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );    
          // console.log()
    }

    else if(theater==''&&region!=''){
      // console.log("3")
      var list = this.lane_region.filter(function(item:any){
        //////console.(item.THEATER_NAME,item.REGION_NAME)
        return item.REGION_NAME===region} ) 
      //////console.(list)
  
      this.filers.fiscal_airportcode_origin = list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );    
    }
    else{
      // console.log("4")
      this.filers.fiscal_airportcode_origin = this.lane_region
      .map((item) => item['Airport Code'])
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      ); 
    }




    if(theaterd!=''&&regiond!='')
    {
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd && item.REGION_NAME===regiond } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );

    }
    else if(theaterd!=''&&regiond==''){
      var list = this.lane_region.filter(function(item:any){return item.THEATER_NAME===theaterd  } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    else if(theaterd==''&&regiond!=''){
      var list = this.lane_region.filter(function(item:any){return  item.REGION_NAME===regiond } ) 
   
      this.filers.fiscal_airportcode_destination= list
          .map((item) => item['Airport Code'])
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
    }
    else{
      this.filers.fiscal_airportcode_destination = this.lane_region
      .map((item) => item['Airport Code'])
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      ); 
    }
        
    // console.log(this.filers.fiscal_airportcode_origin);
    // console.log(this.filers.fiscal_airportcode_destination);
            //console.log(this.filers.fiscal_airportcode_origin )
            //console.log(this.filers.fiscal_airportcode_destination )

  }
   close(){
    this.modalctrl.dismiss();

   }
   resetoverallocation(){
    this.threshold_config.overallocation_threshold_perc=90;
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
   }
  
   saveconfig(){
      // this.warning_threshold_perc=this.threshold_config.warning_threshold_perc;
      // this.overallocation_threshold_perc=this.threshold_config.overallocation_threshold_perc;
      localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
      this.modalctrl.dismiss();

   }
   emailprefn={
       
    caltype:"Fiscal",
    received:true,
    moved:true
 
  }
   saveemailpref(){
    // console.log(this.emailprefn)
    // return;
    this.emailpref.emailpref=this.emailprefn;
    var k={
      id:localStorage.getItem('user'),
      emailpref:JSON.stringify(this.emailpref),

    } 

  this.service.postData(k, 'update_emailpref').then((result) => {
    var temp: any = result;
    
    console.log(temp);
    alert("Updated your email preferences..")
    localStorage.setItem('emailpref',JSON.stringify(this.emailpref));
    this.modalctrl.dismiss();


}).catch(e => {

  alert("Somthing goes wrong!")
     
});
   }
   
   clear_lane(){
    this.filers.fiscal_airportcode_destination=[];
    this.filers.fiscal_airportcode_origin=[];
    this.filterselection.airport_destination="";
    this.filterselection.airport_origin="";
    this.filterselection.theater_origin=""
    this.filterselection.theater_destination=""

    this.filterselection.region_origin=""
    this.filterselection.region_destination=""
   }
  ngOnInit() {

    console.log(this.emailpref);
    this.emailprefn=this.emailpref.emailpref;
  }

}
