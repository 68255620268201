import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './login/login.page';
import { DailtytrainingComponent } from './dailtytraining/dailtytraining.component';
import { BudgetconfigComponent } from './budgetconfig/budgetconfig.component';
import { CapacitypredictionComponent } from './capacityprediction/capacityprediction.component';
import { PartnerDemandsesningComponent } from './partner-demandsesning/partner-demandsesning.component';
import { AirdashboardComponent } from './airdashboard/airdashboard.component';
import { DemandsensingnewComponent } from './demandsensingnew/demandsensingnew.component';
import { InfowindowComponent } from './infowindow/infowindow.component';
import { PartnerAllocationComponent } from './partner-allocation/partner-allocation.component';
import { AllocationmonitorComponent } from './allocationmonitor/allocationmonitor.component';
import { WarehousesampleComponent } from './warehousesample/warehousesample.component';
import { BlogsComponent } from './blogs/blogs.component';
import { DemandsensingspenddashbaordComponent } from './demandsensingspenddashbaord/demandsensingspenddashbaord.component';
import { PartnersourcingComponent } from './partnersourcing/partnersourcing.component';
import { WarehouseconfigComponent } from './warehouseconfig/warehouseconfig.component';

const routes: Routes = [
  { path: '', redirectTo: 'signup', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'planning-strategy',
    loadChildren: () => import('./planning-strategy/planning-strategy.module').then( m => m.PlanningStrategyPageModule)
  },
  {
    path: 'operational-concern',
    loadChildren: () => import('./operational-concern/operational-concern.module').then( m => m.OperationalConcernPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'register-email',
    loadChildren: () => import('./register-email/register-email.module').then( m => m.RegisterEmailPageModule)
  },
  {
    path: 'setpassword',
    loadChildren: () => import('./setpassword/setpassword.module').then( m => m.SetpasswordPageModule)
  },
  {
    path: 'show-aleart/:id',
    loadChildren: () => import('./show-aleart/show-aleart.module').then( m => m.ShowAleartPageModule)
  },
  {
    path: 'portfolio-imbalances',
    loadChildren: () => import('./portfolio-imbalances/portfolio-imbalances.module').then( m => m.PortfolioImbalancesPageModule)
  },
  {
    path: 'portfolio-imbalances-view-data',
    loadChildren: () => import('./portfolio-imbalances-view-data/portfolio-imbalances-view-data.module').then( m => m.PortfolioImbalancesViewDataPageModule)
  },
  {
    path:'login',
    loadChildren:()=>import('./login/login.module').then(m=>m.LoginPageModule)
  },
  {
    path: 'Pibylane',
    loadChildren: () => import('./portfolio-imbalances-by-lane/portfolio-imbalances-by-lane.module').then( m => m.PortfolioImbalancesByLanePageModule)
  },
  {
    path: 'projection-market-impact',
    loadChildren: () => import('./projection-market-impact/projection-market-impact.module').then( m => m.ProjectionMarketImpactPageModule)
  },
  {
    path: 'email-confirm/:id',
    loadChildren: () => import('./email-confirm/email-confirm.module').then( m => m.EmailConfirmPageModule)
  },
  {
    path: 'projectimapctbylane',
    loadChildren: () => import('./projectimapctbylane/projectimapctbylane.module').then( m => m.ProjectimapctbylanePageModule)
  },
  {
    path: 'marketoutlookdetail',
    loadChildren: () => import('./marketoutlookdetail/marketoutlookdetail.module').then( m => m.MarketoutlookdetailPageModule)
  },
  {
    path: 'planned-actual-variances',
    loadChildren: () => import('./planned-actual-variances/planned-actual-variances.module').then( m => m.PlannedActualVariancesPageModule)
  },
  {
    path: 'planned-actual-bylane',
    loadChildren: () => import('./planned-actual-bylane/planned-actual-bylane.module').then( m => m.PlannedActualBylanePageModule)
  },
  {
    path: 'operational-concern1',
    loadChildren: () => import('./operational-concern1/operational-concern1.module').then( m => m.OperationalConcern1PageModule)
  },
  {
    path: 'operational-concern-message',
    loadChildren: () => import('./operational-concern-message/operational-concern-message.module').then( m => m.OperationalConcernMessagePageModule)
  },
  {
    path: 'mle-training',
    loadChildren: () => import('./mle-training/mle-training.module').then( m => m.MleTrainingPageModule)
  },
  {
    path: 'userprofile',
    loadChildren: () => import('./userprofile/userprofile.module').then( m => m.UserprofilePageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'month-over-month',
    loadChildren: () => import('./month-over-month/month-over-month.module').then( m => m.MonthOverMonthPageModule)
  },
  {
    path: 'month-over-monthbylane',
    loadChildren: () => import('./month-over-monthbylane/month-over-monthbylane.module').then( m => m.MonthOverMonthbylanePageModule)
  },
  {
    path: 'capacitytonnage',
    loadChildren: () => import('./capacitytonnage/capacitytonnage.module').then( m => m.CapacitytonnagePageModule)
  },
  {
    path: 'capacitymapdetail',
    loadChildren: () => import('./capacitymapdetail/capacitymapdetail.module').then( m => m.CapacitymapdetailPageModule)
  },
  {
    path: 'benchmarking',
    loadChildren: () => import('./benchmarking/benchmarking.module').then( m => m.BenchmarkingPageModule)
  },
  {
    path: 'hdradashboard',
    loadChildren: () => import('./hdradashboard/hdradashboard.module').then( m => m.HDRAdashboardPageModule)
  },
  {
    path: 'news/:id',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'newsconfig/:id',
    loadChildren: () => import('./newsconfig/newsconfig.module').then( m => m.NewsconfigPageModule)
  },
  {
    path: 'distribution',
    loadChildren: () => import('./distribution/distribution.module').then( m => m.DistributionPageModule)
  },
  {
    path: 'dailymonitering',
    loadChildren: () => import('./dailymonitering/dailymonitering.module').then( m => m.DailymoniteringPageModule)
  },
  {
    path: 'costvariance',
    loadChildren: () => import('./costvariance/costvariance.module').then( m => m.CostvariancePageModule)
  },
  {
    path: 'pricemodelcal',
    loadChildren: () => import('./pricemodelcal/pricemodelcal.module').then( m => m.PricemodelcalPageModule)
  },
  {
    path: 'projections',
    loadChildren: () => import('./projections/projections.module').then( m => m.ProjectionsPageModule)
  },
  {
    path: 'projectionanalyser',
    loadChildren: () => import('./projectionanalyser/projectionanalyser.module').then( m => m.ProjectionanalyserPageModule)
  },
  {
    path: 'demandsensing',
    loadChildren: () => import('./demandsensing/demandsensing.module').then( m => m.DemandsensingPageModule)
  },
  {
    path:'dailytraining',
    component:DailtytrainingComponent

  },
  {
    path: 'budgeting/:id',
    loadChildren: () => import('./budgeting/budgeting.module').then( m => m.BudgetingPageModule)
  }
  ,
  {
    path:'budgetingconfig',
    component:BudgetconfigComponent

  },
  {
    path:'capacityprediction',
    component:CapacitypredictionComponent

  },
  {
    path: 'budgeting-dashboard',
    loadChildren: () => import('./budgeting-dashboard/budgeting-dashboard.module').then( m => m.BudgetingDashboardPageModule)
  },
  {
    path:'partnerdemand/:weak/:lane',
    component:PartnerDemandsesningComponent

  },
  {
    path:'airdashboard',
    component:AirdashboardComponent

  },
  {
    path:'demandsensingnew',
    component:DemandsensingnewComponent

  }
  ,
  {
    path:'info',
    component:InfowindowComponent

  },
  {
    path:'allocation',
    component:PartnerAllocationComponent

  },
  {
    path:'allocationmonitor',
    component:AllocationmonitorComponent

  },
  {
    path:'warehouse',
    component:WarehousesampleComponent

  }
  ,
  {
    path:'blogs',
    component:BlogsComponent

  },
  {
    path:'demandspend',
    component:DemandsensingspenddashbaordComponent

  },
  
  {
    path:'partnersourcing',
    component:PartnersourcingComponent

  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then( m => m.CmsPageModule)
  }
  ,
  {
    path:'warehouseconfig',
    component:WarehouseconfigComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
