import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-modelpopup',
  templateUrl: './modelpopup.component.html',
  styleUrls: ['./modelpopup.component.scss'],
})
export class ModelpopupComponent implements OnInit {
  bardata;

  constructor() {

  }
  bars: any;
  colorArray: any;
  public LineChartdata = {
    chartType: 'ColumnChart',
    dataTable: [
      ['Price item', 'Corelation factor'],
      ['Peak season vs ATA', 0.22],
      ['ATA vs Fuel', 0.43],
      ['Transportation ATA 101-300 Kg vs ATA', -0.77],
      ['Transportation ATA +1000 Kg vs Security Surcharge ($/Kg)', 0.70]

    ],

    options: {
      tooltip: { isHtml: true },    // CSS styling affects only HTML tooltips.
      legend: { position: 'verticle' },
      bar: { groupWidth: '20%' },
     
    }
  }
  ngOnInit() {

  }
  ionViewWillEnter() {
    this.createBarChart();
  }
  createBarChart() {


  }
}
