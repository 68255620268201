import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { Animation, NavOptions } from '@ionic/core';
import { HttpClientModule } from '@angular/common/http';
import { ModelpopupComponent } from './modelpopup/modelpopup.component';
import { ModelviewTopcorelationComponent } from './modelview-topcorelation/modelview-topcorelation.component';
import { ModelLinechartComponent } from './model-linechart/model-linechart.component';
import { ModelViewLineComponent } from './model-view-line/model-view-line.component';
import { PortfolioImbalancesViewPipe } from './portfolio-imbalances-view.pipe';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { WatchlistComponent } from './watchlist/watchlist.component';
import { MarketoutlookComponent } from './marketoutlook/marketoutlook.component';
import { FilterPipe } from './filter.pipe';

import { SharingpageComponent } from './sharingpage/sharingpage.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MessagesPipe } from './messages.pipe';
import { HDRAdashboardconfigComponent } from './hdradashboardconfig/hdradashboardconfig.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MarkdataComponent } from './markdata/markdata.component';
import { DailtytrainingComponent } from './dailtytraining/dailtytraining.component';

import { Daterangepicker } from 'ng2-daterangepicker';
// import { BudgetconfigComponent } from './budgetconfig/budgetconfig.component';
import { OceanShipperDataComponent } from './ocean-shipper-data/ocean-shipper-data.component';
import { Ocean3b81detailsComponent } from './ocean3b81details/ocean3b81details.component';
import { ShipercobdataComponent } from './shipercobdata/shipercobdata.component';
import { CapacitypredictionComponent } from './capacityprediction/capacityprediction.component';
import { CapacitypredectionComponent } from './capacitypredection/capacitypredection.component';
import { PartnerDemandsesningComponent } from './partner-demandsesning/partner-demandsesning.component';
import { PartnerdetailsComponent } from './partnerdetails/partnerdetails.component';
import { AirdashboardComponent } from './airdashboard/airdashboard.component';
import { DemandsensingnewComponent } from './demandsensingnew/demandsensingnew.component';
import { InfowindowComponent } from './infowindow/infowindow.component';
import { DemandsensingconfigComponent } from './demandsensingconfig/demandsensingconfig.component';
import { PartnerAllocationComponent } from './partner-allocation/partner-allocation.component';
import { OthercarrierComponent } from './othercarrier/othercarrier.component';
import { AllocationmonitorComponent } from './allocationmonitor/allocationmonitor.component';
import { ShipperdetailscobComponent } from './shipperdetailscob/shipperdetailscob.component';
import { WarehousesampleComponent } from './warehousesample/warehousesample.component';
import { RemarksComponent } from './remarks/remarks.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { BlogsComponent } from './blogs/blogs.component';
import { QuillModule } from 'ngx-quill';
import { PartnersourcingComponent } from './partnersourcing/partnersourcing.component';
import { MultiKeyValueFilterPipe } from './multi-key-value-filter.pipe';
import { RatebookmompopupComponent } from './ratebookmompopup/ratebookmompopup.component';
import { WarehouseconfigComponent } from './warehouseconfig/warehouseconfig.component';
@NgModule({
  declarations: [AppComponent,WarehouseconfigComponent,RatebookmompopupComponent,PartnersourcingComponent,DemandsensingconfigComponent,BlogsComponent,RemarksComponent,WarehousesampleComponent,ShipperdetailscobComponent,AllocationmonitorComponent,OthercarrierComponent,PartnerAllocationComponent,DemandsensingconfigComponent,InfowindowComponent,DemandsensingnewComponent,AirdashboardComponent,PartnerdetailsComponent,PartnerDemandsesningComponent,CapacitypredectionComponent,CapacitypredictionComponent,ShipercobdataComponent,Ocean3b81detailsComponent,OceanShipperDataComponent,DailtytrainingComponent,MarkdataComponent,ModelpopupComponent,ModelviewTopcorelationComponent,ModelLinechartComponent,ModelViewLineComponent, PortfolioImbalancesViewPipe,WatchlistComponent,MarketoutlookComponent,SharingpageComponent, FilterPipe, MessagesPipe,HDRAdashboardconfigComponent, MultiKeyValueFilterPipe],
//   entryComponents: [BlogsComponent,DemandsensingconfigComponent,RemarksComponent,WarehousesampleComponent,ShipperdetailscobComponent,OthercarrierComponent,AllocationmonitorComponent,PartnerAllocationComponent,PartnerdetailsComponent,DemandsensingconfigComponent,InfowindowComponent,DemandsensingnewComponent,AirdashboardComponent,MarkdataComponent,PartnerDemandsesningComponent,CapacitypredictionComponent,Ocean3b81detailsComponent,OceanShipperDataComponent,DailtytrainingComponent, ModelpopupComponent,ModelviewTopcorelationComponent,ModelLinechartComponent,ModelViewLineComponent,WatchlistComponent,MarketoutlookComponent,SharingpageComponent,HDRAdashboardconfigComponent],
  exports: [
    FormsModule,MatAutocompleteModule,
    ReactiveFormsModule,Ng2GoogleChartsModule
  ],
  imports: [NgMultiSelectDropDownModule.forRoot(),QuillModule,Daterangepicker,DragDropModule,
BrowserModule,HttpClientModule,Ng2GoogleChartsModule,MatAutocompleteModule ,ReactiveFormsModule , FormsModule,IonicModule.forRoot(), AppRoutingModule, BrowserAnimationsModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}


export function myTransitionAnimation(AnimationC: Animation, _: HTMLElement, opts: TransitionOptions): Promise<Animation> {

	const TRANSLATE_DIRECTION = 'translateX';
	const OFF_BOTTOM = '100%';
	const CENTER = '0px';
	const enteringEl = opts.enteringEl;
	const leavingEl = opts.leavingEl;
	const ionPageElement = getIonPageElement(enteringEl);
	const rootTransition = new AnimationC();
	
	rootTransition
	.addElement(ionPageElement)
	.beforeRemoveClass('ion-page-invisible');

	const backDirection = (opts.direction === 'back');

   // animate the component itself
   if (backDirection) {
   	rootTransition
   	.duration(opts.duration || 350)
   	.easing('cubic-bezier(0.3,0,0.66,1)');

   } else {
   	rootTransition
   	.duration(opts.duration || 350)
   	.easing('cubic-bezier(0.3,0,0.66,1)')
   	.fromTo(TRANSLATE_DIRECTION, OFF_BOTTOM, CENTER, true)
   	.fromTo('opacity', 1, 1, true);
   }

   // Animate toolbar if it's there
   const enteringToolbarEle = ionPageElement.querySelector('ion-toolbar');
   if (enteringToolbarEle) {
   	const enteringToolBar = new AnimationC();
   	enteringToolBar.addElement(enteringToolbarEle);
   	rootTransition.add(enteringToolBar);
   }

   // setup leaving view
   if (leavingEl && backDirection) {
   	rootTransition
   	.duration(opts.duration || 350)
   	.easing('cubic-bezier(0.3,0,0.66,1)');

   	const leavingPage = new AnimationC();
   	leavingPage
   	.addElement(getIonPageElement(leavingEl))
   	.fromTo(TRANSLATE_DIRECTION, CENTER, OFF_BOTTOM)
   	.fromTo('opacity', 1, 1);

   	rootTransition.add(leavingPage);
   }

   return Promise.resolve(rootTransition);
}

export interface TransitionOptions extends NavOptions {

	progressCallback?: ((ani: Animation | undefined) => void);
	window: Window;
	baseEl: any;
	enteringEl: HTMLElement;
	leavingEl: HTMLElement | undefined;
}

function getIonPageElement(element: HTMLElement) {
	if (element.classList.contains('ion-page')) {
		return element;
	}
	const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');
	if (ionPage) {
		return ionPage;
	}
	return element;
}
