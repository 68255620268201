// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'multiKeyValueFilter'
// })
// export class MultiKeyValueFilterPipe implements PipeTransform {

//   transform(items: any[], filterCriteria: { [key: string]: any }): any[] {
//     if (!items || !filterCriteria || Object.keys(filterCriteria).length === 0) {
//       return items; // Return original items if no criteria are provided
//     }

//     return items.filter(item => {
//       return Object.keys(filterCriteria).every(key => {
//         const filterValue = filterCriteria[key];
//         // If filterValue is blank, consider it a match for all values for that key
//         return filterValue === '' || item[key] === filterValue;
//       });
//     });
//   }
// }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiKeyValueFilter'
})
export class MultiKeyValueFilterPipe implements PipeTransform {

  transform(items: any[], filterCriteria: { [key: string]: any }, currentPage: number = 1, pageSize: number = 10): any[] {
    if (!items || !filterCriteria || Object.keys(filterCriteria).length === 0) {
      return items.slice((currentPage - 1) * pageSize, currentPage * pageSize); // Return paginated original items if no criteria are provided
    }

    // Filter items based on criteria
    const filteredItems = items.filter(item => {
      return Object.keys(filterCriteria).every(key => {
        const filterValue = filterCriteria[key];
        // If filterValue is blank, consider it a match for all values for that key
        return filterValue === '' || item[key] === filterValue;
      });
    });

    // Apply pagination
    const startIndex = (currentPage - 1) * pageSize;
    return filteredItems.slice(startIndex, startIndex + pageSize);
  }
}