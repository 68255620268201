import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ratebookmompopup',
  templateUrl: './ratebookmompopup.component.html',
  styleUrls: ['./ratebookmompopup.component.scss'],
})
export class RatebookmompopupComponent implements OnInit {
  element;
  preelement;
  serv;
  price;
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}

  colors = {
    "Transportation": "#81D0E8",  // Darker Cool Blue
    "Fuel": "#F5A623",            // Darker Peach
    "Screening": "#40E0D0",       // Darker Orange
    "Security": "#D69A6C",        // Darker Gold
    "Origin": "#A8B2B2",          // Darker Green
    "PickUp": "#f4d03f",          // Darker Yellow
    "Destination": "#5F9EA0"      // Darker Teal
  };
  
  colorslist = [
    {
        "Key": "Transportation",
        "Color": "#81D0E8",
        "value":0,
        "prevalue":0
    },
    {
        "Key": "Fuel",
        "Color": "#F5A623",
        "value":0,
        "prevalue":0
    },
    {
        "Key": "Screening",
        "Color": "#40E0D0",
        "value":0,
        "prevalue":0
    },
    {
        "Key": "Security",
        "Color": "#D69A6C",
        "value":0,
        "prevalue":0
    },
    {
        "Key": "Origin",
        "Color": "#A8B2B2",
        "value":0,
        "prevalue":0
    },
    {
        "Key": "Pick Up",
        "Color": "#f4d03f",
        "value":0,
        "prevalue":0
    },
    {
        "Key": "Destination",
        "Color": "#5F9EA0",
        "value":0,
        "prevalue":0
    }
  ];

  constructor() { }

  ngOnInit() {

    console.log(this.element)
    console.log(this.preelement)
    console.log(this.serv)
    console.log(this.price)
    console.log(this.colorslist)

    var k=0;
    // this.colorslist.forEach(element => {


    // <!--  element['af_Total_EXP'],this.colors.Transportation, element['fsc_Total_EXP'],this.colors.Fuel,element['scr_Sur_Total_EXP'],this.colors.Screening,element['sct_Sur_Total_EXP'],this.colors.Security, element['ow_Total_EXP']+ element['ow_programm_Total_EXP']+element['ow_Scr_Total_EXP']+element['ow_security_Total_EXP'],this.colors.Origin,element['pp_Total_EXP'],this.colors.PickUp, element['destinationmin_Total_EXP']+ element['dw_programm_Total_EXP']+element['dw_Scr_Total_EXP']+element['dw_security_Total_EXP']+element['dlpw_Total_EXP'],this.colors.Destination,'$'+element['EXP PeakRate (=DTD+PS)'].toLocaleString(this.lc,this.opt1) -->


      this.colorslist[0].value=this.element['af_Total_'+this.serv]
      this.colorslist[1].value=this.element['fsc_Total_'+this.serv]
      this.colorslist[2].value=this.element['scr_Sur_Total_'+this.serv]
      this.colorslist[3].value=this.element['sct_Sur_Total_'+this.serv]
      this.colorslist[4].value=this.element['ow_Total_'+this.serv]+ this.element['ow_programm_Total_'+this.serv]+this.element['ow_Scr_Total_'+this.serv]+this.element['ow_security_Total_'+this.serv]
      this.colorslist[5].value=this.element['pp_Total_'+this.serv]
      this.colorslist[6].value= this.element['destinationmin_Total_'+this.serv]+ this.element['dw_programm_Total_'+this.serv]+this.element['dw_Scr_Total_'+this.serv]+this.element['dw_security_Total_'+this.serv]+this.element['dlpw_Total_'+this.serv]


      this.colorslist[0].prevalue=this.preelement['af_Total_'+this.serv]
      this.colorslist[1].prevalue=this.preelement['fsc_Total_'+this.serv]
      this.colorslist[2].prevalue=this.preelement['scr_Sur_Total_'+this.serv]
      this.colorslist[3].prevalue=this.preelement['sct_Sur_Total_'+this.serv]
      this.colorslist[4].prevalue=this.preelement['ow_Total_'+this.serv]+ this.preelement['ow_programm_Total_'+this.serv]+this.preelement['ow_Scr_Total_'+this.serv]+this.preelement['ow_security_Total_'+this.serv]
      this.colorslist[5].prevalue=this.preelement['pp_Total_'+this.serv]
      this.colorslist[6].prevalue= this.preelement['destinationmin_Total_'+this.serv]+ this.preelement['dw_programm_Total_'+this.serv]+this.preelement['dw_Scr_Total_'+this.serv]+this.preelement['dw_security_Total_'+this.serv]+this.preelement['dlpw_Total_'+this.serv]

  }

}
